
.main-nav .nav-link.nav-link-main{
    font-size: calc(16rem/16);
    padding: calc(10rem/16) calc(20rem/16);

    @media screen and (min-width: 1650px){
       font-size: calc(14rem/16);
       padding: calc(15rem/16) calc(10rem/16) calc(30rem/16);
    }

    @media screen and (min-width: 1150px) and (max-width: 1649px){
        font-size: calc(11rem/16);
        padding: calc(15rem/16) calc(5rem/16) calc(30rem/16);
    }
    
    @media screen and (min-width: 768px){
        font-size: calc(11rem/16);
        padding: calc(15rem/16) calc(10rem/16) calc(30rem/16);
    }
}
.navbar-main .nav-item--button .btn{
    @media screen and (min-width: 1150px) and (max-width: 1400px){
        padding: calc(5rem/16);
        font-size: calc(12rem/16);
    }

    @media screen and (min-width: 768px) and (max-width: 1150px){
        font-size: calc(14rem/16);
        padding: calc(10rem/16) calc(10rem/16);

    }
}
.nav-item.is-open > .nav-dropdown-sub__list{
    @media screen and (min-width: 768px){
        opacity: 1;
        visibility: visible;
        transition: opacity 300ms ease, visibility 150ms ease;
    }
}

.nav-item.active .nav-dropdown-sub__list{
    height: auto;
}
.nav-dropdown__list-container .nav-dropdown__link:hover {
    color: var(--color-primary);
}
